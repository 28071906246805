body {
	overflow: hidden;
}

.grecaptcha-badge {
	bottom: 5.625rem !important;
}

li {
	list-style: none;
}
